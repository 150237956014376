import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/config/app.config.service';
import { Observable, catchError, map } from 'rxjs';
import { ErrorService } from 'src/app/core/shared/services/error.service';
import { Template } from 'src/app/core/shared/models/Template';
import { WorkOrder } from 'src/app/core/shared/models/WorkOrder';
import { ConfirmationDetails } from 'src/app/core/shared/models/confirmationDetails.model';

@Injectable({
  providedIn: 'root',
})
export class DetailWorkorderService {
  defaultCompanyId = AppConfigService.settings.defaultCompanyId;
  bcCoreApiUrl = AppConfigService.settings.bcCoreApiUrl;
  customApiUrlSegment = AppConfigService.settings.customApiUrlSegment;
  bcEnvirName = AppConfigService.settings.bcEnvirName;
  defaultLg = AppConfigService.settings.defaultLanguage;
  standardApiUrlSegment = AppConfigService.settings.standardApiUrlSegment;
  schemaVersion = AppConfigService.settings.bcSchemaversion;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService
  ) {}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'If-Match': '*',
    }),
  };
      
  HttpFrOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'If-Match': '*',
      'Accept-Language': 'fr-CA'
    }),
  };

  getWorkOrderTemplate(
    tenantId: any,
    companyId: string,
    templateSystemId: string,
    $filter: string
  ): Observable<any> {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/templateHeaders(' +
      templateSystemId +
      ')?$schemaversion='+this.schemaVersion+'&$expand=columnLines($expand=sectionLines($expand=subSectionLines($expand=taskLines)))&' +
      $filter;
    return this.http.get(url, this.httpOptions).pipe(
      map((result) => result as any),
      catchError(this.errorService.handleError)
    );
  }
  getWorkOrderTemplateWithoutFilter(
    tenantId: any,
    companyId: string,
    templateSystemId: string
  ): Observable<any> {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/templateHeaders(' +
      templateSystemId +
      ')?$schemaversion='+this.schemaVersion+'&$expand=columnLines($expand=sectionLines($expand=subSectionLines($expand=taskLines)))';
    return this.http.get(url, this.httpOptions).pipe(
      map((result) => result as any),
      catchError(this.errorService.handleError)
    );
  }

  getTaskDetails(tenantId: any, companyId: string) {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/portailDefDisplayNames?$schemaversion='+this.schemaVersion;
    return this.http.get(url, this.httpOptions).pipe(
      map((result) => result as any),
      catchError(this.errorService.handleError)
    );
  }

  getTaskCategoryByDoc(tenantId: any, companyId: string, $filter: string) {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/taskCategoriesByDocs?$schemaversion=' +this.schemaVersion+'&'+
      $filter;
    return this.http.get(url, this.httpOptions).pipe(
      map((result) => result as any),
      catchError(this.errorService.handleError)
    );
  }

  getTaskCategoryBySystemId(tenantId: any, companyId: string, sysId:string) {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/taskCategoriesByDocs('+sysId+')?$schemaversion=' +this.schemaVersion;
    return this.http.get(url, this.httpOptions).pipe(
      map((result) => result as any),
      catchError(this.errorService.handleError)
    );
  }


  getStandartApiData(
    tenantId: any,
    companyId: string,
    concatenatedUrl: string
  ) {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.standardApiUrlSegment +
      '/companies(' +
      companyId +
      ')' +
      concatenatedUrl;
    return this.http.get(url, this.httpOptions).pipe(
      map((result) => result as any),
      catchError(this.errorService.handleError)
    );
  }

  getNoStandartApiData(
    tenantId: any,
    companyId: string,
    concatenatedUrl: string
  ) {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')' +
      concatenatedUrl;
    return this.http.get(url, this.httpOptions).pipe(
      map((result) => result as any),
      catchError(this.errorService.handleError)
    );
  }

  getNoStandartApiData_2(
    tenantId: any,
    companyId: string,
    concatenatedUrl: string
  ) {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.standardApiUrlSegment +
      '/companies(' +
      companyId +
      ')' +
      concatenatedUrl;
    return this.http.get(url, this.httpOptions).pipe(
      map((result) => result as any),
      catchError(this.errorService.handleError)
    );
  }

  deleteElement(
    tenantId: any,
    companyId: string,
    concatenatedUrl: string
  ): Observable<any> {
    const schemaversion = "?$schemaversion="+this.schemaVersion
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')' +
      concatenatedUrl+schemaversion;
    return this.http.delete(url, this.HttpFrOptions).pipe(
      map((result) => result as Template),
      catchError(this.errorService.handleError)
    );
  }

  createElement(
    tenantId: any,
    companyId: string,
    data,
    concatenatedUrl: string
  ): Observable<any> {
    const schemaversion = "?$schemaversion="+this.schemaVersion
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')' +
      concatenatedUrl+schemaversion;
    return this.http.post(url, data, this.httpOptions).pipe(
      map((result) => result as Template),
      catchError(this.errorService.handleError)
    );
  }

  getWorkOrderCategoriesWithFilter(
    tenantId: any,
    companyId: string,
    $filter: string
  ): Observable<any> {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/workOrderCategories?$schemaversion='+this.schemaVersion+'&'+
      $filter;
    return this.http.get(url, this.httpOptions).pipe(
      map((result) => result as Template),
      catchError(this.errorService.handleError)
    );
  }

  getWorkOrderLines(
    tenantId: any,
    companyId: string,
    systemId: string,
    $filter: string
  ): Observable<any> {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/workOrders(' +
      systemId +
      ')/workOrderLines?$schemaversion='+this.schemaVersion+'&'+
      $filter;
    return this.http.get(url, this.httpOptions).pipe(
      map((result) => result as Template),
      catchError(this.errorService.handleError)
    );
  }

  getConfirmationDetails(
    tenantId: any,
    companyId: string,
    $filter: string
  ): Observable<any> {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/eraConfirmationDetails?$schemaversion='+this.schemaVersion+'&'+
      $filter;
    return this.http.get(url, this.httpOptions).pipe(
      map((result) => result['value'] as ConfirmationDetails[]),
      catchError(this.errorService.handleError)
    );
  }

  editElement(
    tenantId: any,
    companyId: string,
    systemId: string,
    data,
    concatenatedUrl: string
  ): Observable<any> {
    const schemaversion = "?$schemaversion="+this.schemaVersion
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/workOrders(' +
      systemId +
      ')' +
      concatenatedUrl+schemaversion;

    return this.http.patch(url, data, this.HttpFrOptions).pipe(
      map((result) => result as Template),
      catchError(this.errorService.handleError)
    );
  }

    editItemVariantElement(
    tenantId: any,
    companyId: string,
    data,
    concatenatedUrl: string
  ): Observable<any> {
    const schemaversion = "?$schemaversion="+this.schemaVersion
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/' +
      concatenatedUrl+schemaversion;
    return this.http.patch(url, data, this.httpOptions).pipe(
      map((result) => result as Template),
      catchError(this.errorService.handleError)
    );
  }

  getWorkorders(tenantId: any, companyId: string, $filter): Observable<any> {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/workOrders?$schemaversion='+this.schemaVersion+'&$filter='+
      $filter;
    return this.http.get(url, this.httpOptions).pipe(
      map((result) => result as WorkOrder[]),
      catchError(this.errorService.handleError)
    );
  }

  getSubstitutsItem(
    tenantId: any,
    companyId: string,
    $filter: string
  ): Observable<any> {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/eraworkOrderLines?$schemaversion='+this.schemaVersion+'&$expand=eraSubstitution&' +
      $filter;
    return this.http
      .get(url, this.httpOptions)
      .pipe(catchError(this.errorService.handleError));
  }

  getBikeParts(tenantId: any, companyId: string, $filter): Observable<any> {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/getPickingLists?$schemaversion='+this.schemaVersion+'&$expand=eraworkOrderLines($expand=eraSubstitution)&$filter=' +
      $filter;
    return this.http
      .get(url, this.httpOptions)
      .pipe(catchError(this.errorService.handleError));
  }

  patchBikePartsSubstitut(
    tenantId: any,
    companyId: string,
    systemId,
    data: any
  ): Observable<any> {
    const schemaversion = "?$schemaversion="+this.schemaVersion
    const url =
      this.bcCoreApiUrl +
      '/' +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/eraReplaceBySubstatut(' +
      systemId +
      ')'+schemaversion;
    return this.http
      .patch(url, JSON.stringify(data), this.httpOptions)
      .pipe(catchError(this.errorService.handleError));
  }
}
