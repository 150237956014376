<nav class="sidebar sidebar-offcanvas position-fixed h-100" id="sidebar">
  <ul *ngIf="currentProfile.profil === 'Evaluator'" class="nav add-margin-top">
    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item dropend'
          : pageName == 'templateMaker'
          ? 'nav-item dropend'
          : pageName == 'templates'
          ? 'nav-item dropend'
          : pageName == 'validationWorkorder'
          ? 'nav-item dropend'
          : pageName == 'bikepartslist'
          ? 'nav-item dropend'
          : ''
      ">
      <a data-toggle="tooltip" data-placement="right" title="Scanner" class="nav-link" [routerLink]="
          '/home/' + currentUserCompany.id + '/' + currentProfile.id
        ">
        <i class="fa fa-qrcode menu-icon"></i>
        <span class="menu-title">Scanner</span>
      </a>
    </li>
    <!-- <li class="nav-item d-none">
      <a data-toggle="tooltip" data-placement="right" title="Validation" class="nav-link" [routerLink]="'/validationworkorder/'+currentUserCompany.id+'/'+currentProfile.id +'/serial-number/'+equipementNo" href="#">
        <i class="fa fa-check menu-icon"></i>
        <span class="menu-title">Valider les bons de travail</span>
      </a>
    </li> -->

    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item d-none'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item d-none'
          : pageName == 'templateMaker'
          ? 'nav-item dropend'
          : pageName == 'templates'
          ? 'nav-item dropend'
          : pageName == 'validationWorkorder'
          ? 'nav-item dropend'
          : pageName == 'bikepartslist'
          ? 'nav-item d-none'
          : ''
      ">
      <a class="nav-link" data-toggle="tooltip" data-placement="right" title="Archives" [routerLink]="
          '/archiveworkorder/' +
          currentUserCompany.id +
          '/' +
          currentProfile.id +
          '/serial-number/' +
          equipementNo
        ">
        <i class="fa fa-folder-open menu-icon"></i>
        <span class="menu-title">Bons de travail archivés</span>
      </a>
    </li>

    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item d-none'
          : pageName == 'templateMaker'
          ? 'nav-item d-none'
          : pageName == 'templates'
          ? 'nav-item d-none'
          : pageName == 'validationWorkorder'
          ? 'nav-item d-none'
          : pageName == 'bikepartslist'
          ? 'nav-item d-none'
          : ''
      ">
      <a data-toggle="tooltip" (click)="onEquipementAssocieClick()" data-placement="right" title="Équipements associés"
        class="nav-link pe-auto" href="javascript:" data-bs-whatever="@mdo">
        <i class="fa fa-cog menu-icon"></i>
        <span class="menu-title">Equipements associés</span>
      </a>
    </li>

    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item d-none'
          : pageName == 'templateMaker'
          ? 'nav-item d-none'
          : pageName == 'templates'
          ? 'nav-item d-none'
          : pageName == 'validationWorkorder'
          ? 'nav-item d-none'
          : pageName == 'bikepartslist'
          ? 'nav-item dropend'
          : ''
      ">
      <a data-toggle="tooltip" data-placement="right" title="Suivi de consommation" class="nav-link pe-auto" href="#"
        data-bs-toggle="modal" data-bs-target="#consumptionTrackModal" data-bs-whatever="@mdo">
        <i class="fa fa-eye menu-icon"></i>
        <span class="menu-title">Suivi de consommation</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="sidebarFeatures.readTemplate">
      <a class="nav-link" data-toggle="tooltip" data-placement="right" title="Templates" [routerLink]="
          '/templates/' +
          currentUserCompany.id +
          '/' +
          currentProfile.id +
          '/serial-number/' +
          equipementNo
        " href="#">
        <i class="fa fa-cubes menu-icon"></i>
        <span class="menu-title">Templates</span>
      </a>
    </li>

    <!-- <li class='nav-item dropend'>
      <a data-toggle="tooltip" data-placement="right" title="Revue avant confirmation finale" class="nav-link pe-auto"
        href="#" data-bs-toggle="modal" data-bs-target="#reviewBeforeConfirmModal">
        <i class="fa fa-check menu-icon"></i>
        <span class="menu-title">Revue avant confirmation finale</span>
      </a>
    </li> -->
  </ul>

  <ul *ngIf="currentProfile.profil === 'Mechanic'" class="nav add-margin-top">
    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item dropend'
          : pageName == 'templateMaker'
          ? 'nav-item dropend'
          : pageName == 'templates'
          ? 'nav-item dropend'
          : pageName == 'validationWorkorder'
          ? 'nav-item dropend'
          : pageName == 'bikepartslist'
          ? 'nav-item dropend'
          : ''
      ">
      <a data-toggle="tooltip" data-placement="right" title="Scanner" class="nav-link" [routerLink]="
          '/home/' + currentUserCompany.id + '/' + currentProfile.id
        ">
        <i class="fa fa-qrcode menu-icon"></i>
        <span class="menu-title">Scanner</span>
      </a>
    </li>
    <!-- <li class="nav-item d-none">
      <a data-toggle="tooltip" data-placement="right" title="Validation" class="nav-link" [routerLink]="'/validationworkorder/'+currentUserCompany.id+'/'+currentProfile.id +'/serial-number/'+equipementNo" href="#">
        <i class="fa fa-check menu-icon"></i>
        <span class="menu-title">Valider les bons de travail</span>
      </a>
    </li> -->

    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item d-none'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item d-none'
          : pageName == 'templateMaker'
          ? 'nav-item dropend'
          : pageName == 'templates'
          ? 'nav-item dropend'
          : pageName == 'validationWorkorder'
          ? 'nav-item dropend'
          : pageName == 'bikepartslist'
          ? 'nav-item d-none'
          : ''
      ">
      <a data-toggle="tooltip" data-placement="right" title="Archives" class="nav-link" [routerLink]="
          '/archiveworkorder/' +
          currentUserCompany.id +
          '/' +
          currentProfile.id +
          '/serial-number/' +
          equipementNo
        ">
        <i class="fa fa-folder-open menu-icon"></i>
        <span class="menu-title">Bons de travail archivés</span>
      </a>
    </li>

    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item d-none'
          : pageName == 'templateMaker'
          ? 'nav-item d-none'
          : pageName == 'templates'
          ? 'nav-item d-none'
          : pageName == 'validationWorkorder'
          ? 'nav-item dropend'
          : pageName == 'bikepartslist'
          ? 'nav-item d-none'
          : ''
      ">
      <a data-toggle="tooltip" (click)="onEquipementAssocieClick()" data-placement="right" title="Équipements associés"
        class="nav-link pe-auto" href="javascript:" data-bs-whatever="@mdo">
        <i class="fa fa-cog menu-icon"></i>
        <span class="menu-title">Equipements associés</span>
      </a>
    </li>
    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item d-none'
          : pageName == 'templateMaker'
          ? 'nav-item d-none'
          : pageName == 'templates'
          ? 'nav-item d-none'
          : pageName == 'validationWorkorder'
          ? 'nav-item d-none'
          : pageName == 'bikepartslist'
          ? 'nav-item dropend'
          : ''
      ">
      <a data-toggle="tooltip" data-placement="right" title="Suivi de consommation" class="nav-link pe-auto" href="#"
        data-bs-toggle="modal" data-bs-target="#consumptionTrackModal" data-bs-whatever="@mdo">
        <i class="fa fa-eye menu-icon"></i>
        <span class="menu-title">Suivi de consommation</span>
      </a>
    </li>
    <li class="nav-item" *ngIf="sidebarFeatures.readTemplate">
      <a data-toggle="tooltip" data-placement="right" title="Templates" class="nav-link" [routerLink]="
          '/templates/' +
          currentUserCompany.id +
          '/' +
          currentProfile.id +
          '/serial-number/' +
          equipementNo
        " href="#">
        <i class="fa fa-cubes menu-icon"></i>
        <span class="menu-title">Templates</span>
      </a>
    </li>
  </ul>

  <ul *ngIf="currentProfile.profil === 'Supervisor'" class="nav add-margin-top">
    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item dropend'
          : pageName == 'templateMaker'
          ? 'nav-item dropend'
          : pageName == 'templates'
          ? 'nav-item dropend'
          : pageName == 'validationWorkorder'
          ? 'nav-item dropend'
          : pageName == 'bikepartslist'
          ? 'nav-item dropend'
          : ''
      ">
      <a data-toggle="tooltip" data-placement="right" title="Scanner" class="nav-link" [routerLink]="
          '/home/' + currentUserCompany.id + '/' + currentProfile.id
        ">
        <i class="fa fa-qrcode menu-icon"></i>
        <span class="menu-title">Scanner</span>
      </a>
    </li>
    <!-- <li [class]="pageName == 'openWorkorder' ? 'nav-item d-none' : pageName == 'detailWorkorder' ? 'nav-item d-none' : pageName == 'home' ? 'nav-item dropend' : pageName == 'archiveWorkorder' ? 'nav-item d-none' : pageName == 'templateMaker' ? 'nav-item d-none' : pageName == 'templates' ? 'nav-item d-none' : pageName == 'validationWorkorder' ? 'nav-item d-none': '' ">
      <a data-toggle="tooltip" data-placement="right" title="Validation" class="nav-link" [routerLink]="'/validationworkorder/'+currentUserCompany.id+'/'+currentProfile.id +'/serial-number/'+equipementNo">
        <i class="fa fa-check menu-icon"></i>
        <span class="menu-title">Valider les bons de travail</span>
      </a>
    </li> -->

    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item d-none'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item d-none'
          : pageName == 'templateMaker'
          ? 'nav-item dropend'
          : pageName == 'templates'
          ? 'nav-item dropend'
          : pageName == 'validationWorkorder'
          ? 'nav-item dropend'
          : pageName == 'bikepartslist'
          ? 'nav-item d-none'
          : ''
      ">
      <a data-toggle="tooltip" data-placement="right" title="Archives" class="nav-link" [routerLink]="
          '/archiveworkorder/' +
          currentUserCompany.id +
          '/' +
          currentProfile.id +
          '/serial-number/' +
          equipementNo
        " href="#">
        <i class="fa fa-folder-open menu-icon"></i>
        <span class="menu-title">Bons de travail archivés</span>
      </a>
    </li>

    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item d-none'
          : pageName == 'templateMaker'
          ? 'nav-item d-none'
          : pageName == 'templates'
          ? 'nav-item d-none'
          : pageName == 'validationWorkorder'
          ? 'nav-item d-none'
          : pageName == 'bikepartslist'
          ? 'nav-item d-none'
          : ''
      ">
      <a data-toggle="tooltip" (click)="onEquipementAssocieClick()" data-placement="right" title="Équipements associés"
        class="nav-link pe-auto" href="javascript:" data-bs-whatever="@mdo">
        <i class="fa fa-cog menu-icon"></i>
        <span class="menu-title">Equipements associés</span>
      </a>
    </li>

    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item d-none'
          : pageName == 'templateMaker'
          ? 'nav-item d-none'
          : pageName == 'templates'
          ? 'nav-item d-none'
          : pageName == 'validationWorkorder'
          ? 'nav-item d-none'
          : pageName == 'bikepartslist'
          ? 'nav-item dropend'
          : ''
      ">
      <a data-toggle="tooltip" data-placement="right" title="Suivi de consommation" class="nav-link pe-auto" href="#"
        data-bs-toggle="modal" data-bs-target="#consumptionTrackModal" data-bs-whatever="@mdo">
        <i class="fa fa-eye menu-icon"></i>
        <span class="menu-title">Suivi de consommation</span>
      </a>
    </li>
    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item dropend'
          : pageName == 'archiveWorkorder'
          ? 'nav-item dropend'
          : pageName == 'templateMaker'
          ? 'nav-item dropend'
          : pageName == 'templates'
          ? 'nav-item d-none'
          : pageName == 'validationWorkorder'
          ? 'nav-item dropend'
          : pageName == 'bikepartslist'
          ? 'nav-item dropend'
          : ''
      " *ngIf="sidebarFeatures.readTemplate">
      <a data-toggle="tooltip" data-placement="right" title="Templates" class="nav-link" [routerLink]="
          '/templates/' +
          currentUserCompany.id +
          '/' +
          currentProfile.id +
          '/serial-number/' +
          equipementNo
        " href="#">
        <i class="fa fa-cubes menu-icon"></i>
        <span class="menu-title">Templates</span>
      </a>
    </li>
  </ul>

  <ul *ngIf="currentProfile.profil === 'Administrator'" class="nav add-margin-top">
    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item dropend'
          : pageName == 'archiveWorkorder'
          ? 'nav-item dropend'
          : pageName == 'templateMaker'
          ? 'nav-item dropend'
          : pageName == 'bikepartslist'
          ? 'nav-item dropend'
          : pageName == 'templates'
          ? 'nav-item d-none'
          : pageName == 'validationWorkorder'
          ? 'nav-item dropend'
          : ''
      " *ngIf="sidebarFeatures.readTemplate">
      <a data-toggle="tooltip" data-placement="right" title="Templates" class="nav-link" [routerLink]="
          '/templates/' +
          currentUserCompany.id +
          '/' +
          currentProfile.id +
          '/serial-number/' +
          equipementNo
        " href="#">
        <i class="fa fa-cubes menu-icon"></i>
        <span class="menu-title">Templates</span>
      </a>
    </li>
    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item d-none'
          : pageName == 'templateMaker'
          ? 'nav-item d-none'
          : pageName == 'templates'
          ? 'nav-item d-none'
          : pageName == 'validationWorkorder'
          ? 'nav-item d-none'
          : pageName == 'bikepartslist'
          ? 'nav-item d-none'
          : ''
      ">
      <a data-toggle="tooltip" (click)="onEquipementAssocieClick()" data-placement="right" title="Équipements associés"
        class="nav-link pe-auto" href="javascript:" data-bs-whatever="@mdo">
        <i class="fa fa-cog menu-icon"></i>
        <span class="menu-title">Equipements associés</span>
      </a>
    </li>

    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item d-none'
          : pageName == 'templateMaker'
          ? 'nav-item d-none'
          : pageName == 'templates'
          ? 'nav-item d-none'
          : pageName == 'validationWorkorder'
          ? 'nav-item d-none'
          : pageName == 'bikepartslist'
          ? 'nav-item dropend'
          : ''
      ">
      <a data-toggle="tooltip" data-placement="right" title="Suivi de consommation" class="nav-link pe-auto" href="#"
        data-bs-toggle="modal" data-bs-target="#consumptionTrackModal" data-bs-whatever="@mdo">
        <i class="fa fa-eye menu-icon"></i>
        <span class="menu-title">Suivi de consommation</span>
      </a>
    </li>



  </ul>
</nav>